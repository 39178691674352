<template>
  <component :is="layout">
    <div class="fadeInUp" role="main">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <h5>Field group configuration</h5>
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-body">
              <router-link
                :to="{ name: 'config.forms.create' }"
                class="btn btn-primary lift"
                >Create field group</router-link
              >
              <div v-if="fieldGroupGonfigs.length">
                <div v-for="config in fieldGroupGonfigs" :key="config.id">
                  <p>
                    {{ config.name }} - {{ config.key }}
                    <router-link
                      :to="{
                        name: 'config.forms.edit',
                        params: { id: config.id },
                      }"
                      class="float-right"
                      >Edit</router-link
                    >
                  </p>
                </div>
              </div>
              <div v-else>
                <h2>
                  @todo Intro what is this<br />You don't have any active field
                  configs
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
export default {
  name: "FieldGroupConfigList",
  components: {
    Default,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("questionnaire/config").then((response) => {
      next((vm) => {
        vm.fieldGroupGonfigs = response.data.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      fieldGroupGonfigs: [],
      modalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
